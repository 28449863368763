export const accidentTypes = [
  'Нарушение при управлении ТС задним ходом',
  'Превышение скорости',
  'Нарушение правил перевозки груза',
  'Техническая неисправность',
  'Правила пользования внешних световых приборов',
  'Нарушение требований дорожных знаков/разметки',
  'Нарушение пешеходами',
  'Алкогольное опьянение',
  'Прочие нарушения'
]
